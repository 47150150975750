<template>

  <e-scrollable-search-filter-page :service="service" ref="page"
                                   v-on:enableSpinner="enableSpinner()"
                                   v-on:disableSpinner="disableSpinner()">

    <template v-slot:header-controls="{ created, searchText, page }">
      <b-button-toolbar key-nav class="float-right">
        <b-button-group class="mx-1">
          <b-button type="button" size="sm" variant="secondary" class="pl-2 pr-2" @click="syncZipCodes(searchText, page)">
            <b-icon icon="arrow-left-right" />
            {{ $t('controls.sync') }}
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </template>

    <template v-slot:spinner>

      <b-spinner v-if="loading"
                 class="spinner"
                 label="Loading..."/>

    </template>

    <template v-slot:entity-content="context">

      <detail-view :id="context.id"
                   :link="context.entity._links.self.href"
                   v-on:enableSpinner="enableSpinner()"
                   v-on:disableSpinner="disableSpinner()"/>

    </template>

  </e-scrollable-search-filter-page>
</template>

<script>
import service from "@/services/zipCodeArea.service";
import DetailView from "@/components/ZipCodeAreaView";
import {log} from "@emons/emons-vue"
import CustomerCreateModal from "@/components/modals/CustomerCreateModal.vue";

export default {
  name: 'ZipCodeAreaList',
  components: {CustomerCreateModal, DetailView},
  data() {
    return {
      loading: false,
      service: service
    }
  },
  methods: {
    enableSpinner: function () {
      log('debug', 'enableSpinner() called...')
      this.loading = true
    },
    disableSpinner: function () {
      log('debug', 'disableSpinner() called...')
      this.loading = false
    },
    syncZipCodes: async function (searchText, page) {
      let filter = this.service.getFilter()
      if (!filter.country.selected || filter.country.selected?.length == 0) {
        this.$bvModal.msgBoxOk(this.$t("sync.select-country"))
      } else if (filter.country.selected?.length > 1) {
        this.$bvModal.msgBoxOk(this.$t("sync.multiple-countries"))
      } else if (!searchText) {
        this.$bvModal.msgBoxOk(this.$t("sync.no-query"))
      } else if (page.totalElements > 100) {
        this.$bvModal.msgBoxOk(this.$t("sync.too-many-results"))
      } else {
        try {
          this.enableSpinner();
          await this.service.sync(searchText, this.service.getFilter())
          this.disableSpinner();
          this.$bvModal.msgBoxOk(this.$t("sync.success")).then(() => this.$refs.page.find());
        } catch (ex) {
          this.disableSpinner();
          this.$bvModal.msgBoxOk(this.$t("sync.failed") + ": " + ex.response?.data?.message)
        }
      }
    }
  }
}
</script>

<style scoped>

.spinner {
  position: absolute;
  z-index: 100000;
  top: 25%;
  left: 50%;
}

</style>