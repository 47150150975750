import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"

const rel = 'zipCodeAreas'
const schema = {
    country: null,
    zipCode: null,
    city: null
}
const header = {
    country: {
        name: 'Land',
        col: 'col-2',
        property: 'country'
    },
    zipCode: {
        name: 'PLZ',
        col: 'col-3',
        property: 'zipCode'
    },
    city: {
        name: 'Ort',
        col: 'col-3',
        property: 'city'
    }
}
const filter = {
    country: {
        name: 'Land',
        orderBy: 'country,asc',
        selectable: [],
        selected: [],
        negate: false,
        links: {},
        page: {},
        api: '/api/zipCodeAreas/supportedCountries',
        rel: 'countries',
        timeout: null
    }
}
const sync = null;
const initialSort = 'country'
const projection = 'list'
const create = false

const Service = {
    getHeader: function () {
        return header
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function() {
        return initialSort
    },
    getProjection: function () {
        return projection
    },
    getSupportedCountries: async function() {
      try {
          return (await ApiService.get('/api/zipCodeAreas/supportedCountries')).data
      } catch (error) {
          log('error', 'Error querying supported countries:', error)
      }
    },
    sync: async function(query, filters) {
      return ApiService.post('/api/zipCodeAreas', null,{
          params: {
              country: (filters && filters.country && filters.country.selected && filters.country.selected.length > 0)
                  ? filters.country.selected.join(',')
                  : null,
              query: query
          }
      })
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get('/api/zipCodeAreas', {
                params: {
                    country: (filters && filters.country && filters.country.selected && filters.country.selected.length > 0)
                        ? filters.country.selected.join(',')
                        : null,
                    query: query,
                    sort: (sortProperty?sortProperty:'city') + ',' + (sortDirection?sortDirection:'ASC'),
                    size: size != null ? size : 250
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    findInFilter: async function (filterItem, searchText, queryDropDown) {
        try {
            return ApiService.get(filterItem['api'], {
                params: {
                    query: searchText,
                    sort: filterItem.orderBy,
                    size: 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let response = JSON.parse(data)

                        log("debug", response)

                        // filterItem.links = response['_links']
                        // filterItem.page = response['page']
                        filterItem.selectable = response
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying filter elements:", error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData && parsedData['_embedded'] && parsedData['_embedded'][rel]) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'][rel]
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(this.cleanLink(link), {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    cleanLink: function (link) {
        if (link) {
            if (link.indexOf('{') > -1)
                link = link.split('{')[0];

            if (link.indexOf('?') > -1)
                link = link.split('?')[0];

            return link;
        } else
            return link;
    }
}

export default Service